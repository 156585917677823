import {useGetExampleQuery} from "./chemistry";

export const ChemistryPage = () => {
    // const fetcher = useFetcher();

    const {isLoading, data} = useGetExampleQuery()
    // useEffect(() => {
    //     fetcher.load("chemistry/example");
    // }, [fetcher]);

    return (
        <div>
            ChemistryPage
            <pre>
                {isLoading ? "Loading..." : JSON.stringify(data || {}, null, 2)}
            </pre>
        </div>
    );
};

