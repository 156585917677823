import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import {ChemistryPage} from "./pages";

export const AppRouter = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: '/chemistry',
        element: <ChemistryPage/>
    }
]);
