import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const chemistryApi = createApi({
    reducerPath: 'chemistryApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/chemistry' }),
    endpoints: (builder) => ({
        getExample: builder.query<object, void>({
            query: () => `example`,
        }),
    }),
})

export const {
    useGetExampleQuery
} = chemistryApi;