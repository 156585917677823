import { configureStore } from '@reduxjs/toolkit'
import {chemistryApi} from "./pages/chemistry";
import {setupListeners} from "@reduxjs/toolkit/query";

export const AppStore = configureStore({
    reducer: {
        [chemistryApi.reducerPath]: chemistryApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(chemistryApi.middleware),
})

setupListeners(AppStore.dispatch)


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof AppStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof AppStore.dispatch